
import { Component } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';

import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import Dialog from '@/modules/common/components/ui-kit/dialog.vue';

import Item from '@/modules/common/interfaces/item.interface';
import { HotelNetConfig } from '@/modules/compsets/interfaces';

import { SettingsTab } from '../../interfaces/settings-tab.abstract';

@Component({
    components: {
        CustomSelect,
        Dialog,
    },
})
export default class NetCalculationTab extends SettingsTab {
    static title = 'Net Calculation';

    static adminOnly = true;

    @Inject(CompsetsServiceS)
    private compsetsService!: CompsetsService;

    @Inject(ProvidersServiceS)
    private providerService!: ProvidersService;

    @Inject(HotelsServiceS)
    private hotelsService!: HotelsService;

    public filterData = {
        compset: null as string | null,
        provider: null as string | null,
        pos: 'US',
    };

    public haveSavingError = false;
    public isSaving = false;
    public isLoadingCompsets = false;
    public statusText = '';
    public tableData: HotelNetConfig[] = [];

    public compsetChangeConfirmation: string | null = null;
    private changedData: Record<number, true> = {};

    public get haveChanges() {
        return Object.keys(this.changedData).length > 0;
    }

    public mounted() {
        this.applyFilterDefaults();
    }

    public async save() {
        const { pos, compset, provider } = this.filterData;

        if (!provider || !pos || !compset) {
            return;
        }

        const tableData = this.tableData.filter(node => this.changedData[node.id]);

        this.isSaving = true;
        this.statusText = '';

        try {
            const hotelId = +this.$route.params.hotelId!;
            await this.compsetsService
                .saveNetCalculationSettings(hotelId, compset, provider, pos, tableData);

            this.clearChangesData();
            this.statusText = 'Saved!';
        } catch {
            this.statusText = 'Error!';
        } finally {
            this.isSaving = false;
        }
    }

    get posItems() {
        if (!this.choosenCompset) return [];

        return this.choosenCompset.pos
            .map(pos => ({
                value: pos,
                name: pos,
            }));
    }

    get providerItems() {
        if (!this.choosenCompset) return [];
        const { rateProviders } = this.choosenCompset;

        return this.providerService
            .toItemsList(Array.from(new Set(rateProviders.filter(p => !['all', 'cheapest'].includes(p)))));
    }

    get compsetItems(): Item[] {
        const { compsets } = this.compsetsService;

        if (!compsets) {
            return [];
        }

        return compsets
            .map(compset => ({
                value: compset.id,
                name: `${compset.name} (${this.$t(`filterSettings.compset.${compset.type}`)})`,
            }));
    }

    get hotelId() {
        return +this.$route.params.hotelId;
    }

    get choosenCompset() {
        const { compset: compsetId } = this.filterData;
        const compset = this.compsetsService.getCompset(compsetId);

        return compset;
    }

    public async refreshTableData() {
        const { pos, provider } = this.filterData;
        const { hotelId, choosenCompset } = this;

        if (!choosenCompset || !provider) {
            this.tableData = [];

            return;
        }

        const hotelIds = [hotelId, ...choosenCompset.competitors];
        const hotelNames = await this.hotelsService.getHotelNames(hotelIds);

        const currentNetSettings = this.compsetsService
            .getCurrentNetCalculationSettings(choosenCompset.id, provider, pos);

        this.tableData = hotelIds.map(hotelId => {
            const hotelName = hotelNames[hotelId];

            currentNetSettings[hotelId] = currentNetSettings[hotelId] || {
                vat: 0,
                extraFees: 0,
            };

            return {
                hotelName,
                id: hotelId,
                vat: currentNetSettings[hotelId].vat,
                extraFees: currentNetSettings[hotelId].extraFees,
            };
        });
    }

    public async saveAndChangeCompset() {
        if (!this.compsetChangeConfirmation) return;

        await this.save();
        this.setCompset(this.compsetChangeConfirmation, true);
    }

    public async setCompset(newCompset: string, force: boolean = false) {
        if (this.haveChanges && !force) {
            this.compsetChangeConfirmation = newCompset;
            return;
        }

        await this.applyFilterDefaults();
        this.filterData.compset = newCompset;
        this.compsetChangeConfirmation = null;
        this.clearChangesData();
        this.refreshTableData();
    }

    public async setProvider(p: string | null) {
        this.filterData.provider = p;
        this.refreshTableData();
    }

    public async setPos(p: string) {
        this.filterData.pos = p;
        this.refreshTableData();
    }

    private async applyFilterDefaults() {
        let { compsets } = this.compsetsService;

        if (!compsets) {
            this.isLoadingCompsets = true;
            await this.compsetsService.loadData(this.hotelId);
            this.isLoadingCompsets = false;
        }

        ({ compsets } = this.compsetsService);

        if (!compsets) return;

        this.filterData.compset = compsets[0].id || null;
        this.filterData.provider = this.providerItems[0]?.value || 'booking';
        this.filterData.pos = this.posItems[0]?.value || 'US';

        this.refreshTableData();
    }

    public updateChangeState(hotelId: number) {
        this.changedData = {
            ...this.changedData,
            [hotelId]: true,
        };
    }

    public checkValue(index: number) {
        const item = this.tableData[index];

        item.extraFees = +item.extraFees || 0;
        item.vat = +item.vat || 0;

        this.tableData = [...this.tableData];
    }

    private clearChangesData() {
        this.changedData = {};
    }
}
