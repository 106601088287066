
import { Component } from 'vue-property-decorator';
import { CompsetSettings } from '@/modules/compsets/components/compset-settings';
import { SettingsTab } from '../../interfaces/settings-tab.abstract';

@Component({
    components: {
        CompsetSettings,
    },
})
export default class CompsetSettingsTab extends SettingsTab {
    static title = 'Compset Settings';
    static isFullwidth = true;
}
