var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ExpansionPanel',{ref:'panel',class:{
        'room-mapping-group': true,
        'room-mapping-group--select-mode': _vm.haveSelectedRooms,
        'room-mapping-group--pending': _vm.isPending,
    },attrs:{"expanded":_vm.expanded},scopedSlots:_vm._u([{key:"head",fn:function({ toggleExpand }){return [_c('div',{class:{
                'room-mapping-group__column room-mapping-group__column--head': true,
                'room-mapping-group__column--main-hotel': _vm.hotelData.id === _vm.mainHotelId,
                'room-mapping-group__column--hotel-name': true,
            },attrs:{"title":_vm.hotelData.name},on:{"click":toggleExpand}},[_c('p',{staticClass:"hotel-name",class:_vm.haveSelectedRooms ? 'hotel-name--compressed' : ''},[_c('i',{staticClass:"el-icon-arrow-right"}),_vm._v(" "+_vm._s(_vm.hotelData.name)+" ")]),_c('div',[(_vm.haveSelectedRooms)?_c('button',{staticClass:"btn",attrs:{"title":"Cancel selection"},on:{"click":_vm.resetSelected}},[_c('i',{staticClass:"icon-X-01"})]):_vm._e(),(_vm.isAdmin && _vm.haveSelectedRooms)?_c('button',{staticClass:"btn",attrs:{"title":_vm.archivedOnly ? 'Restore' : 'Archive'},on:{"click":_vm.archiveSelectedRooms}},[(!_vm.archivedOnly)?_c('i',{staticClass:"icon-archive"}):_c('i',{staticClass:"icon-CI_refresh-icon"})]):_vm._e()])]),_vm._l((_vm.roomTypes),function(room){return _c('div',{key:room.id,class:{
                'room-mapping-group__column': true,
                'room-mapping-group__column--head': true,
                'room-mapping-group__column--room-type': true,
                'room-mapping-group__column--folded': _vm.isFoldedRoomType(room.id),
            }},[_c('div',[_c('span',{staticClass:"room-mapping-group__fold-trigger",attrs:{"title":'Click to ' + (_vm.isFoldedRoomType(room.id) ? 'unfold' : 'fold')},on:{"click":function($event){return _vm.toggleRoomTypeFold(room.id)}}},[(room.id === 2)?[_c('i',{staticClass:"icon-Lockmessage",attrs:{"title":"This is mandatory room"}}),_vm._v("   ")]:_vm._e(),_vm._v(" "+_vm._s(room.name)+" ")],2),(room.id !== -2)?[_vm._v("   "),_c('CustomDropdown',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isFoldedRoomType(room.id)),expression:"!isFoldedRoomType(room.id)"}],ref:"dropdown",refInFor:true,attrs:{"items":_vm.getDropdownItemsForRoom(room.id),"eventMask":"roomtype"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"icon-Icons_action"})]},proxy:true}],null,true)})]:_vm._e()],2),(!_vm.archivedOnly && _vm.haveSelectedRooms)?_c('button',{staticClass:"btn room-mapping-group__move-button",attrs:{"title":"Move here"},on:{"click":function($event){return _vm.moveSelectedRooms(room.id)}}},[_c('i',{staticClass:"icon-download"})]):_vm._e()])})]}},{key:"body",fn:function({ isExpanded }){return [(isExpanded)?[_c('div',{staticClass:"room-mapping-group__column room-mapping-group__column--providers"},_vm._l((_vm.actualProviders),function(provider){return _c('div',{key:provider.value,staticClass:"room-mapping-group__room-group",style:({
                        '--rooms-count': _vm.getMaxRoomsCount(provider.value),
                    })},[(provider.value !== 'Archived')?_c('ProviderCard',{staticClass:"room-mapping-group__provider",attrs:{"provider":provider.value}}):_c('div',{staticClass:"room-mapping-group__provider",domProps:{"textContent":_vm._s(provider.name)}})],1)}),0),_vm._l((_vm.roomTypes),function(roomType){return _c('div',{key:roomType.id,class:{
                    'room-mapping-group__column': true,
                    'room-mapping-group__column--rooms': true,
                    'room-mapping-group__column--folded': _vm.isFoldedRoomType(roomType.id),
                }},[_c('div',{staticClass:"room-mapping-group__folded-label"},[(roomType.id === 2)?[_c('i',{staticClass:"icon-Lockmessage",attrs:{"title":"This is mandatory room"}}),_vm._v("   ")]:_vm._e(),_vm._v(" "+_vm._s(roomType.name)+" ")],2),_vm._l((_vm.actualProviders),function(provider){return _c('div',{key:provider.value + roomType.id,staticClass:"room-mapping-group__room-group",style:({
                        '--rooms-count': _vm.getMaxRoomsCount(provider.value),
                    })},_vm._l((_vm.getRooms(provider.value, roomType.id)),function(roomName){return _c('div',{key:roomName + provider.value,ref:roomName + provider.value,refInFor:true,class:{
                            'room-mapping-group__room-item': true,
                            'room-mapping-group__room-item--selected': _vm.isSelected(provider.value, roomType.id, roomName),
                        },attrs:{"title":roomName},domProps:{"textContent":_vm._s(roomName)},on:{"click":function($event){return _vm.toggleSelect(provider.value, roomType.id, roomName)}}})}),0)})],2)})]:_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }