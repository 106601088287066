
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import USER_LEVELS from '@/modules/user/constants/user-levels.constant';
import HOTEL_NOTIFICATIONS from '@/modules/user/constants/hotel-level-notifications';

import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';

import ExpansionPanel from '@/modules/common/components/ui-kit/expansion-panel.vue';
import CustomSwitch from '@/modules/common/components/ui-kit/custom-switch.vue';
import CustomCheckbox from '@/modules/common/components/ui-kit/custom-checkbox.vue';

import { fieldsConfig } from '../../config/alerts-notification.config';
import { SettingsTab } from '../../interfaces/settings-tab.abstract';

import DynamicDescription from '../dynamic-description.vue';
import HotelNotificationsGroup from '../hotel-notifications-group.vue';

@Component({
    components: {
        CustomSwitch,
        HotelNotificationsGroup,
        ExpansionPanel,
        CustomCheckbox,
        DynamicDescription,
    },
})
export default class AlertsAndNotificationsTab extends SettingsTab {
    static title = 'Alerts & Notifications';

    @Inject(UserServiceS) userService!: UserService;
    @Inject(UserSettingsS) userSettings!: UserSettingsService;

    changedGroups: string[] = [];
    hotelChangedFields: string[] = [];
    hiddenFields: string[] = [
        'rateChange',
        'rateDrop',
    ];

    statusText = '';
    haveSavingError = false;

    fieldsConfig = fieldsConfig;
    groupIcons = {
        rates: 'icon-CI_Rateshopper',
        Rates: 'icon-CI_Rateshopper',
        Reports: 'icon-Knowledege-icon',
        Visibility: 'icon-CI_visibility',
        Promotions: 'icon-CI_Promotion',
        'Guest Reviews': 'icon-CI_Reviews',
    };

    get isGeneralMode() {
        return this.$route.query.mode === 'general';
    }

    get hotelNotifications() {
        return HOTEL_NOTIFICATIONS;
    }

    get notificationList() {
        return Object
            .entries(this.userSettings.alertsAndNotifications)
            .filter(([key]) => !this.hiddenFields.includes(key));
    }

    get groups() {
        return this.userSettings.alertsAndNotificationsByGroup;
    }

    get isSaving() {
        return this.userSettings.isSaving;
    }

    get haveChanges() {
        return this.changedGroups.length || this.hotelChangedFields.length;
    }

    get isHotel() {
        if (!this.userService.user) {
            return false;
        }

        return this.userService.user.level === USER_LEVELS.HOTEL
            || this.userService.isViewAsHotel;
    }

    beforeMount() {
        this.defineConditionValueField();
        this.updateDescriptions();
    }

    updateChangeState(groupKey: string) {
        this.changedGroups = [
            ...this.changedGroups,
            groupKey,
        ];
    }

    updateHotelChangeState(key: string) {
        this.hotelChangedFields = [
            ...this.hotelChangedFields,
            key,
        ];
    }

    updateDescriptions() {
        if (!this.userSettings.alertsAndNotifications) {
            return;
        }

        Object.entries(this.fieldsConfig).forEach(([key, config]) => {
            const params = this.userSettings.alertsAndNotifications[key];
            if (config.descriptionTemplate) {
                params.conditions = config.descriptionTemplate
                    .replace(/{([\w+.]+)}/g, (_, propertyPath) => propertyPath
                        .split('.')
                        .reduce(
                            (prev: any, curr: string) => (prev ? prev[curr] : prev),
                            this.userSettings.alertsAndNotifications,
                        ));
            }
        });
    }

    validateAndFix(groupKey: string, key: string) {
        const { [key]: config } = this.fieldsConfig;
        const { [key]: params } = this.userSettings
            .alertsAndNotificationsByGroup[groupKey].alertsAndNotifications;

        Vue.set(params, 'conditionsValue', params.conditionsValue || []);

        if (config && config.fields) {
            config.fields.forEach((field, i) => {
                if (field.fix) {
                    const oldValue = params.conditionsValue![i] || field.default;

                    params.conditionsValue!.splice(i, 1, field.fix(oldValue));
                }
            });

            this.updateDescriptions();
        }
    }

    defineConditionValueField() {
        const { alertsAndNotificationsByGroup } = this.userSettings;

        const processGroup = (groupKey: string) => Object
            .keys(alertsAndNotificationsByGroup[groupKey].alertsAndNotifications)
            .forEach(key => {
                const fieldConfig = this.fieldsConfig[key];
                const params = alertsAndNotificationsByGroup[groupKey]
                    .alertsAndNotifications[key];
                this.validateAndFix(groupKey, key);

                if (!fieldConfig) return;

                if (!params.conditionsValue && fieldConfig.fields) {
                    params.conditionsValue = fieldConfig.fields
                        .map(field => field.default);
                }
            });

        Object
            .keys(alertsAndNotificationsByGroup)
            .forEach(processGroup);
    }

    isGroupEnabled(groupKey: string) {
        return this.groups[groupKey].active;
    }

    toggleGroup(groupKey: string) {
        this.groups[groupKey].active = !this.groups[groupKey].active;
        this.updateChangeState(groupKey);
    }

    async save() {
        this.haveSavingError = false;

        try {
            await this.userSettings
                .saveAlertsAndNotifications(this.hotelChangedFields as HOTEL_NOTIFICATIONS[]);
            this.resetChangingInfo();
            this.statusText = 'Saved!';
        } catch (err) {
            this.haveSavingError = true;
            this.statusText = 'Something went wrong. Try again later';

            throw err;
        }
    }

    private resetChangingInfo() {
        this.changedGroups = [];
        this.hotelChangedFields = [];
    }
}
