
import { Input, DatePicker } from 'element-ui';
import { Inject } from 'inversify-props';
import { Component, Vue, Prop } from 'vue-property-decorator';

import Dialog from '@/modules/common/components/ui-kit/dialog.vue';
import SpecialDateModel from '@/modules/user/models/special-date.model';
import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import CustomSwitch from '@/modules/common/components/ui-kit/custom-switch.vue';

const dateIntl = new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric', year: 'numeric' });

@Component({
    components: {
        ElInput: Input,
        ElDatePicker: DatePicker,
        Dialog,
        CustomSwitch,
    },

    filters: {
        FormatDate(value: string) {
            const date = new Date(value);

            return dateIntl.format(date);
        },
    },
})
export default class SpecialDatesSection extends Vue {
    @Inject(UserSettingsS)
    private userSettingsService!: UserSettingsService;

    public currentSpecialDate: SpecialDateModel | null = null;
    public dateToRemove: SpecialDateModel | null = null;
    public isOverflowModalOpened = false;
    public isPending = false;
    public startEndDates: [string | null, string | null] | null = [null, null];

    pickerOptions = {
        disabledDate(time: Date) {
            const today = new Date();
            today.setDate(today.getDate() - 1);

            return time.getTime() < today.getTime();
        },
    };

    @Prop({ type: Boolean })
    private value!: boolean;

    public get specialDatesEnabled() {
        return this.value;
    }

    public set specialDatesEnabled(value: boolean) {
        this.$emit('input', value);
    }

    public get specialDates() {
        return this.userSettingsService.intradaySpecialDates;
    }

    public get isNewDate() {
        if (!this.currentSpecialDate) return false;

        return !this.currentSpecialDate.id;
    }

    private get isFull() {
        return this.specialDates.length >= 3;
    }

    private get isDateRangeValid() {
        if (!this.currentSpecialDate) return false;
        if (!this.startEndDates) return false;

        const [start, end] = this.startEndDates;

        return start !== end;
    }

    private get isDateRangeExpired() {
        if (!this.currentSpecialDate) return false;
        if (!this.startEndDates) return false;

        const today = new Date();
        const [start] = this.startEndDates;
        const startDate = new Date(start!);
        today.setDate(today.getDate() - 1);

        return startDate.getTime() < today.getTime();
    }

    private get isDateNameValid() {
        if (!this.currentSpecialDate) return false;

        return !!this.currentSpecialDate.name
            && this.currentSpecialDate.name.length >= 3
            && this.currentSpecialDate.name.length <= 30;
    }

    private get isDateNameExists() {
        if (!this.currentSpecialDate) return false;

        return this.specialDates
            .some(date => true
                && date.name.trim() === this.currentSpecialDate!.name.trim()
                && date.id !== this.currentSpecialDate!.id);
    }

    public get isCreationFormValid() {
        return !this.creationFormErrorMessage;
    }

    public get creationFormErrorMessage() {
        if (!this.currentSpecialDate) return '';

        if (this.isDateNameExists) {
            return 'A special date with this name already exists';
        }

        if (!this.isDateNameValid) {
            return 'Name should be between 3 and 30 characters';
        }

        if (!this.isDateRangeValid) {
            return 'Date range should be more than 1 day';
        }

        if (this.isDateRangeExpired) {
            return 'Date range should start from today and later';
        }

        return '';
    }

    public createNew() {
        if (this.isFull) {
            this.isOverflowModalOpened = true;
            return;
        }

        const start = new Date().toISOString().split('T').shift()!;
        const endDate = new Date();
        endDate.setDate(endDate.getDate() + 1);

        const end = endDate.toISOString().split('T').shift()!;

        this.currentSpecialDate = {
            name: 'New Special date',
            start,
            end,
        } as SpecialDateModel;

        this.startEndDates = [start, end];
    }

    public async save() {
        const { currentSpecialDate } = this;
        if (!currentSpecialDate) return;
        if (!this.startEndDates) return;

        currentSpecialDate.start = this.startEndDates[0]!;
        currentSpecialDate.end = this.startEndDates[1]!;

        this.isPending = true;

        try {
            if (this.isNewDate) {
                await this.userSettingsService.addSpecialDate(currentSpecialDate);
            } else {
                await this.userSettingsService.updateSpecialDate(currentSpecialDate);
            }
        } finally {
            this.isPending = false;
        }

        this.closeForm();
    }

    public async edit(date: SpecialDateModel) {
        const ref = new SpecialDateModel();
        ref.id = date.id;
        ref.name = date.name;
        ref.start = date.start;
        ref.end = date.end;

        this.startEndDates = [date.start, date.end];

        this.currentSpecialDate = ref;
    }

    public async remove(date: SpecialDateModel) {
        if (!this.dateToRemove) {
            this.dateToRemove = date;

            return;
        }

        this.isPending = true;

        try {
            await this.userSettingsService.deleteSpecialDate(this.dateToRemove.id);
            this.dateToRemove = null;
        } finally {
            this.isPending = false;
        }
    }

    public closeRemoveDialog() {
        this.dateToRemove = null;
    }

    public closeForm() {
        this.currentSpecialDate = null;
    }
}
