var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"net-calculation-settings"},[_c('div',{staticClass:"settings-section"},[_c('div',{staticClass:"settings-section__heading"},[_vm._v(" Set calculation ")]),_c('div',{staticClass:"net-calculation-settings__filter-bar"},[_c('div',[_vm._v(" Comp set: "),_c('CustomSelect',{attrs:{"value":_vm.filterData.compset,"items":_vm.compsetItems},on:{"input":_vm.setCompset}})],1),_c('div',[_vm._v(" Source: "),_c('CustomSelect',{attrs:{"value":_vm.filterData.provider,"items":_vm.providerItems},on:{"input":_vm.setProvider}})],1),_c('div',[_vm._v(" POS: "),_c('CustomSelect',{attrs:{"value":_vm.filterData.pos,"items":_vm.posItems},on:{"input":_vm.setPos}})],1)]),_c('div',{staticClass:"net-calculation-settings__table-scroll"},[_c('table',{class:{
                    'net-calculation-settings__table table': true,
                    'net-calculation-settings__table--loading': _vm.isLoadingCompsets,
                }},[_vm._m(0),_c('tbody',_vm._l((_vm.tableData),function(item,index){return _c('tr',{key:item.id,class:{
                            'net-calculation-settings__row': true,
                            'net-calculation-settings__row--main-hotel': _vm.hotelId === item.id,
                        }},[_c('td',[_vm._v(" "+_vm._s(item.hotelName)+" ")]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.vat),expression:"item.vat"}],attrs:{"type":"number"},domProps:{"value":(item.vat)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(item, "vat", $event.target.value)},function($event){return _vm.updateChangeState(item.id)}],"change":function($event){return _vm.checkValue(index)}}}),_vm._v("% ")]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.extraFees),expression:"item.extraFees"}],attrs:{"type":"number"},domProps:{"value":(item.extraFees)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(item, "extraFees", $event.target.value)},function($event){return _vm.updateChangeState(item.id)}],"change":function($event){return _vm.checkValue(index)}}}),_vm._v("$ ")])])}),0)])]),_c('div',{staticClass:"settings-section__action-bar"},[_c('div',{class:{
                    'settings-section__status': true,
                    'settings-section__status--error': _vm.haveSavingError,
                }},[_vm._v(" "+_vm._s(_vm.statusText)+" ")]),_c('button',{staticClass:"btn btn_primary",class:{ 'btn--waiting': _vm.isSaving },attrs:{"disabled":_vm.isSaving || !_vm.haveChanges},on:{"click":_vm.save}},[_vm._v(" Update ")])])]),_c('Dialog',{class:{
            'net-calculation-settings__confirm': true,
            'net-calculation-settings__confirm--pending': _vm.isSaving,
        },model:{value:(_vm.compsetChangeConfirmation),callback:function ($$v) {_vm.compsetChangeConfirmation=$$v},expression:"compsetChangeConfirmation"}},[_vm._v(" Would you like to save changes before changing the compset? By clicking \"No\" all changes will be lost. "),_c('div',{staticClass:"dialog__action-bar"},[_c('button',{staticClass:"btn btn_danger",on:{"click":function($event){return _vm.setCompset(_vm.compsetChangeConfirmation, true)}}},[_vm._v(" No ")]),_c('button',{staticClass:"btn btn_primary btn_outline",on:{"click":function($event){return _vm.saveAndChangeCompset()}}},[(!_vm.isSaving)?[_vm._v(" Save and change ")]:[_vm._v(" Saving... ")]],2),_c('button',{staticClass:"btn btn_primary",on:{"click":function($event){_vm.compsetChangeConfirmation = null}}},[_vm._v(" Cancel ")])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('thead',[_c('tr',[_c('th',[_vm._v(" Hotel name ")]),_c('th',{attrs:{"width":"200"}},[_vm._v(" VAT ")]),_c('th',{attrs:{"width":"200"}},[_vm._v(" Extra fees ")])])])
}]

export { render, staticRenderFns }