
import { Component } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import ChartColors from '../sections/chart-colors.vue';
import { SettingsTab } from '../../interfaces/settings-tab.abstract';

@Component({
    components: {
        ChartColors,
    },
})
export default class ScanPropertiesTab extends SettingsTab {
    @Inject(UserSettingsS) private userSettingsService!: UserSettingsService;

    static title = 'Color Palette';

    get colors() {
        return this.userSettingsService.chartColors;
    }
}
