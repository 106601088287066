
import { Component } from 'vue-property-decorator';
import SubpageController from '../subpage-controller.vue';

import EmailReportSection from '../sections/email-reports.section.vue';
import { SettingsTab } from '../../interfaces/settings-tab.abstract';

@Component({
    components: {
        SubpageController,
        EmailReportSection,
    },
})
export default class ReportPropertiesTab extends SettingsTab {
    static title = 'E-Mail Reports';
}
