
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';
import CurrencySwitcherTooltip from '@/modules/common/components/currency-switcher-tooltip.vue';

@Component({
    components: {
        CustomSelect,
        StyledSearchbar,
        CurrencySwitcherTooltip,
    },
})
export default class CurrencySection extends Vue {
    @Inject(UserSettingsS) userSettingsService!: UserSettingsService;

    isStatusError = false;
    currentCurrency: string | null = null;
    status = '';
    query = '';

    beforeMount() {
        this.currentCurrency = this.userSettingsService.displayCurrency;
    }

    get currencyItems() {
        const { allCurrencies } = this.userSettingsService;
        const currencies = allCurrencies
            ? allCurrencies.currencies
            : {};

        const currencyList = Object
            .entries(currencies)
            .map(([_, curr]) => ({
                name: `${curr.code}`,
                value: curr.code,
            }))
            .filter(
                item => item.name
                    .toLowerCase()
                    .includes(this.query.toLowerCase()),
            );

        currencyList.splice(0, 0, {
            name: 'By selected POS',
            value: null as unknown as string,
        });

        return currencyList;
    }

    get isCurrencyLoading() {
        return this.userSettingsService.isCurrenciesLoading();
    }

    async saveCurrency() {
        this.status = '';
        this.isStatusError = false;

        try {
            await this.userSettingsService
                .updateDisplayCurrency(this.currentCurrency);

            if (this.currentCurrency !== null) {
                this.status = 'Saved!';
            } else {
                this.status = 'Currency has reset to POS value';
            }
        } catch (err) {
            this.status = 'Error! Try again';
            this.isStatusError = true;
        }
    }

    resetCurrency() {
        this.currentCurrency = null;
        this.saveCurrency();
    }

    updateQuery(newQuery: string) {
        this.query = newQuery;
    }
}
