
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import UserSettings, { UserSettingsS } from '@/modules/user/user-settings.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import COMPSET_TYPE from '@/modules/compsets/constants/compset-type.constant';

@Component({
    components: {
        CustomSelect,
    },
})
export default class EmailReportsSubpage extends Vue {
    static title = 'E-Mail Reports';

    @Inject(ProvidersServiceS)
    private providersService!: ProvidersService;

    @Inject(UserSettingsS)
    private userSettings!: UserSettings;

    isChanged = false;
    statusText = '';

    yesNoItems = [
        { name: 'Yes', value: true },
        { name: 'No', value: false },
    ];

    updateChangeStatus() {
        this.isChanged = true;
    }

    get compsetSettings() {
        return this.userSettings.compsetSettings;
    }

    get isCompsetSettingsLoading() {
        return this.userSettings.isCompsetSettingsLoading;
    }

    get subscriptions() {
        return this.userSettings.subscriptions;
    }

    get isGeneralMode() {
        return this.$route.query.mode === 'general';
    }

    get isSaving() {
        return this.userSettings.isSaving;
    }

    get isAbleToSave() {
        return this.isChanged && !this.isSaving;
    }

    get sourceList() {
        return this.userSettings.storeState.compsetAllowedReportProviders
            .filter(value => value !== 'all')
            .map(value => {
                const name = this.providersService
                    .getProviderLabel(value);
                return { name, value };
            });
    }

    async saveSettings() {
        await this.userSettings.saveReportsSettings();
        this.statusText = 'Saved!';
        this.isChanged = false;
    }
}
