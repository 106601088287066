
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import ExpansionPanel from '@/modules/common/components/ui-kit/expansion-panel.vue';
import CustomCheckbox from '@/modules/common/components/ui-kit/custom-checkbox.vue';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import CustomSwitch from '@/modules/common/components/ui-kit/custom-switch.vue';

import Item from '@/modules/common/interfaces/item.interface';
import HotelModel from '@/modules/hotels/models/hotel.model';
import HotelNotifications from '@/modules/user/constants/hotel-level-notifications';

import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';

import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';

@Component({
    components: {
        CustomMultiSelect,
        CustomCheckbox,
        CustomSwitch,
        ExpansionPanel,
    },
})
export default class HotelNotificationsGroup extends Vue {
    @Inject(UserSettingsS)
    private userSettingsService!: UserSettingsService;

    @Inject(HotelsServiceS)
    private hotelsService!: HotelsService;

    get options(): Item[] {
        const { hotelId } = this;

        return this.hotelsService.allHotels
            .filter((hotel: HotelModel) => hotel.id !== hotelId)
            .map(hotel => ({
                name: hotel.name,
                value: hotel.id,
            }));
    }

    get isGroupEnabled() {
        return this.rateChangeActive || this.competitorSoldOutActive;
    }

    set isGroupEnabled(value) {
        this.rateChangeActive = value;
        this.competitorSoldOutActive = value;
    }

    get hotelId() {
        return +this.$route.params.hotelId;
    }

    get rateChangeValue() {
        return this.getValue(HotelNotifications.COMPETITOR_RATE_CHANGE);
    }

    set rateChangeValue(values: Item[]) {
        this.setValue(HotelNotifications.COMPETITOR_RATE_CHANGE, values);
    }

    get rateChangeActive() {
        const alertOptions = this.getAlertOptions(HotelNotifications.COMPETITOR_RATE_CHANGE);

        if (!alertOptions || alertOptions.active === undefined) {
            return false;
        }

        return alertOptions.active;
    }

    set rateChangeActive(value: boolean) {
        this.updateActiveState(HotelNotifications.COMPETITOR_RATE_CHANGE, value);
    }

    get rateChangePercent() {
        const alertOptions = this.getAlertOptions(HotelNotifications.COMPETITOR_RATE_CHANGE);

        if (!alertOptions) return 15;

        return alertOptions.conditionsValue || 15;
    }

    set rateChangePercent(value: number) {
        this.updatePercentValue(HotelNotifications.COMPETITOR_RATE_CHANGE, +value);
    }

    set competitorSoldOutActive(value: boolean) {
        this.updateActiveState(HotelNotifications.COMPETITOR_SOLD_OUT, value);
    }

    get competitorSoldOutActive() {
        const alertOptions = this.getAlertOptions(HotelNotifications.COMPETITOR_SOLD_OUT);

        if (!alertOptions || alertOptions.active === undefined) {
            return false;
        }

        return alertOptions.active;
    }

    get competitorSoldOutValue() {
        return this.getValue(HotelNotifications.COMPETITOR_SOLD_OUT);
    }

    set competitorSoldOutValue(values: Item[]) {
        this.setValue(HotelNotifications.COMPETITOR_SOLD_OUT, values);
    }

    updatePercentValue(key: HotelNotifications, value: number) {
        const alertOptions = this.getAlertOptions(key);
        if (!alertOptions) return;

        alertOptions.conditionsValue = +value;

        this.$emit('change', key);
    }

    updateActiveState(key: HotelNotifications, value: boolean) {
        const { hotelId } = this;
        const alertOptions = this.getAlertOptions(key);

        if (!alertOptions) return;

        alertOptions.active = value;
        this.userSettingsService.assignHotelSettingsFor(hotelId, {
            [key]: alertOptions,
        });

        this.$emit('change', key);
    }

    validateRateChangePercent(e: Event) {
        const input = e.currentTarget as HTMLInputElement;

        const max = Math.max(+input.value, 15);
        this.rateChangePercent = max;

        input.value = String(max);
    }

    getAlertOptions(key: HotelNotifications) {
        const { hotelId } = this;
        const { hotelAlertsAndNotifications } = this.userSettingsService;

        if (!hotelAlertsAndNotifications || !hotelAlertsAndNotifications[hotelId]) {
            return null;
        }

        if (!hotelAlertsAndNotifications[hotelId][key]) {
            this.userSettingsService.assignHotelSettingsFor(hotelId, {
                [key]: {
                    active: true,
                    fornovaIds: [],
                },
            });
        }

        return hotelAlertsAndNotifications[hotelId][key];
    }

    getValue(key: HotelNotifications) {
        const alertOptions = this.getAlertOptions(key);

        if (!alertOptions || !alertOptions.fornovaIds) {
            return [] as Item[];
        }

        return alertOptions.fornovaIds.map(id => {
            const item = this.options.find(option => option.value === id);
            return {
                name: item ? item.name : String(id),
                value: id,
            };
        });
    }

    setValue(key: HotelNotifications, values: Item[]) {
        const alertOptions = this.getAlertOptions(key);
        const { hotelId } = this;

        if (!alertOptions) return;

        alertOptions.fornovaIds = values.map(item => Number(item.value));

        if (!values.length) {
            alertOptions.active = false;
        }

        this.userSettingsService.assignHotelSettingsFor(hotelId, {
            [key]: alertOptions,
        });

        this.$emit('change', key);
    }

    toggleGroup() {
        this.isGroupEnabled = !this.isGroupEnabled;
    }
}
