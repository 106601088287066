
import { Component } from 'vue-property-decorator';
import { SettingsTab } from '../../interfaces/settings-tab.abstract';
import CurrencySection from '../sections/currency.section.vue';

@Component({
    components: {
        CurrencySection,
    },
})
export default class ScanPropertiesTab extends SettingsTab {
    static title = 'Scan Properties';
}
