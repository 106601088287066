
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import UserService, { UserServiceS } from '@/modules/user/user.service';
import OpenTelemetryService, { OpenTelemetryServiceS } from '@/modules/open-telemetry/open-telemetry.service';
import { LOGTYPE } from '@/modules/open-telemetry/constants';

import Dialog from '@/modules/common/components/ui-kit/dialog.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import TabsController from '@/modules/common/components/tabs-controller.vue';
import ReportPropertiesTab from '../components/tabs/reports-properties.tab.vue';
import IntradaysPropertiesTab from '../components/tabs/intradays-properties.tab.vue';
import ScanPropertiesTab from '../components/tabs/scan-properties.tab.vue';
import AlertsAndNotificationsTab from '../components/tabs/alerts-notifications.tab.vue';
import ColorPalette from '../components/tabs/color-palette.tab.vue';
import NetCalculationTab from '../components/tabs/net-calculation.tab.vue';
import { SettingsTab } from '../interfaces/settings-tab.abstract';
import RoomTypeMappingTab from '../components/tabs/room-type-mapping.tab.vue';
import DefaultFiltersTab from '../components/tabs/default-filters.tab.vue';
import CompsetSettingsTab from '../components/tabs/compset-settings.tab.vue';
import ScheduledReportsTab from '../components/tabs/scheduled-reports.tab.vue';

enum SettingsMode {
    GENERAL = 'general',
    HOTEL = 'hotel',
}

interface SettingsTabsMap {
    [mode: string]: (typeof SettingsTab)[];
}

const ModeNames = {
    [SettingsMode.GENERAL]: 'General',
    [SettingsMode.HOTEL]: 'Hotel Settings',
} as Record<SettingsMode, string>;

@Component({
    components: {
        PageWrapper,
        TabsController,
        Dialog,
    },
})
export default class SettingsPage extends Vue {
    @Inject(UserServiceS)
    private userService!: UserService;

    @Inject(OpenTelemetryServiceS)
    private otelService!: OpenTelemetryService;

    public settingsMode: SettingsMode = SettingsMode.GENERAL;
    public currentTab = 0;

    public tabsMap: SettingsTabsMap = {
        [SettingsMode.GENERAL]: [
            ScanPropertiesTab,
            IntradaysPropertiesTab,
            AlertsAndNotificationsTab,
            ReportPropertiesTab,
            ColorPalette,
            DefaultFiltersTab,
        ],
        [SettingsMode.HOTEL]: [
            NetCalculationTab,
            RoomTypeMappingTab,
            AlertsAndNotificationsTab,
            ReportPropertiesTab,
            CompsetSettingsTab,
            ScheduledReportsTab,
        ],
    };

    beforeMount() {
        this.settingsMode = this.$route.query.mode as SettingsMode || SettingsMode.GENERAL;
        this.currentTab = +this.$route.query.tab || 0;
        this.updateQueryParameter();

        const name = `settings-${this.tabs[this.currentTab].title.toLowerCase().split(' ').join('-')}-${this.settingsMode}`;
        this.otelService.startSpan({ name, prefix: LOGTYPE.VISITPAGE });
    }

    public get tabs() {
        const { isAdmin } = this.userService;
        return this.tabsMap[this.settingsMode]
            .filter(tabComponent => {
                const solveShowing = 'showSolver' in tabComponent
                    ? tabComponent.showSolver
                    : null;

                if (!isAdmin && tabComponent.adminOnly) {
                    return false;
                }

                if (!solveShowing) return true;

                return solveShowing.bind(this)();
            });
    }

    get isViewAsHotel() {
        return !!this.$route.params.hotelId;
    }

    get modes() {
        return ModeNames;
    }

    isModeActive(mode: SettingsMode) {
        return this.settingsMode === mode;
    }

    isModeAllowed(mode: SettingsMode) {
        if (mode === SettingsMode.HOTEL && !this.isViewAsHotel) {
            return false;
        }
        return true;
    }

    async setTab(index: number) {
        if (await this.onTabLeavePrevented()) {
            return;
        }

        this.currentTab = index;
        this.updateQueryParameter();

        const name = `settings-${this.tabs[this.currentTab].title.toLowerCase().split(' ').join('-')}-${this.settingsMode}`;
        this.otelService.startSpan({ name, prefix: LOGTYPE.VISITPAGE });
    }

    async setMode(mode: SettingsMode) {
        if (this.settingsMode === mode) {
            return;
        }

        if (await this.onTabLeavePrevented()) {
            return;
        }

        this.settingsMode = mode;
        this.currentTab = 0;
        this.updateQueryParameter();
    }

    private async onTabLeavePrevented() {
        const name = `settings-${this.tabs[this.currentTab].title.toLowerCase().split(' ').join('-')}-${this.settingsMode}`;
        this.otelService.endSpan({ name, prefix: LOGTYPE.VISITPAGE }, { sendLogs: true });

        const tabComponent = this.tabs[this.currentTab];

        if (!tabComponent) return false;

        if (tabComponent.onTabLeave) {
            let result = tabComponent.onTabLeave();

            if (result instanceof Promise) {
                result = await result;
            }

            return !result;
        }

        return false;
    }

    private updateQueryParameter() {
        const isTabChanged = this.currentTab !== +this.$route.query.tab;
        const isModeChanged = this.settingsMode !== this.$route.query.mode;
        if (!isTabChanged && !isModeChanged) return;

        this.$router.push({
            name: this.$route.name!,
            params: {
                ...this.$route.params,
            },
            query: {
                tab: String(this.currentTab),
                mode: this.settingsMode,
            },
        });
    }
}
