
import { Inject } from 'inversify-props';
import { Vue, Prop, Component } from 'vue-property-decorator';

import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import { fieldsConfig } from '../config/alerts-notification.config';

const PARAMETERS_REGEX = /\{(.+?)\}/g;
const STRINGS_REGEX = /\{.+?\}/;

@Component
export default class DynamicDescription extends Vue {
    @Inject(UserSettingsS)
    private userSettings!: UserSettingsService;

    @Prop({ type: Object })
    public params!: UserAPI.AlertsAndNotifications['param'];

    @Prop({ type: String })
    public paramKey!: string;

    @Prop({ type: String })
    public groupKey!: string;

    private get sourceString() {
        const paramConfig = fieldsConfig[this.paramKey];
        const defaultString = this.userSettings
            .alertsAndNotificationsByGroup[this.groupKey]
            .alertsAndNotifications[this.paramKey].conditions;

        if (!paramConfig) return defaultString;

        return paramConfig.descriptionTemplate || defaultString;
    }

    public get strings() {
        if (!this.sourceString) return [];

        return this.sourceString.split(STRINGS_REGEX);
    }

    public get fields() {
        if (!this.sourceString) return [];

        const params = this.sourceString
            .match(PARAMETERS_REGEX);

        return (params || [])
            .map(fieldIndex => fieldsConfig[this.paramKey].fields![+fieldIndex[1]]);
    }

    public handleInput(key: string, value: string) {
        this.$emit('input', { key, value });
    }
    public handleChange(key: string, value: string) {
        this.$emit('change', { key, value });
    }
}
