
import { Component, Vue, Prop } from 'vue-property-decorator';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import Item from '@/modules/common/interfaces/item.interface';

/**
 * Is used in default-filters.tab
 * Represents default filter row
 */
@Component({
    components: {
        CustomSelect,
    },
})
export default class SelectSection extends Vue {
    @Prop({ type: String })
    title!: string;

    @Prop({ type: String })
    label!: string;

    @Prop({ type: Array })
    items!: Item[];

    @Prop({ type: [Number, String] })
    value!: string | number;

    get v() {
        return this.value;
    }

    set v(v: string | number) {
        this.$emit('input', v);
    }
}
