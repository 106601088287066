
import { Component } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { $enum } from 'ts-enum-util';

import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';

import DEFAULT_LOS from '@/modules/document-filters/constants/default-los.constant';
import NUMBER_OF_GUESTS from '@/modules/document-filters/constants/number-of-guests.constant';
import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import Item from '@/modules/common/interfaces/item.interface';
import { DefaultFilters } from '@/modules/user/types';
import { SettingsTab } from '../../interfaces/settings-tab.abstract';
import SelectSection from '../select-section.vue';

@Component({
    components: { SelectSection },
})
export default class DefaultFiltersTab extends SettingsTab {
    @Inject(UserSettingsS)
    private userSettingsService!: UserSettingsService;

    @Inject(MealTypesServiceS)
    private mealTypesService!: MealTypesService;

    @Inject(RatesCommonServiceS)
    private ratesCommonService!: RatesCommonService;

    static title = 'Default Filters';

    status = '';
    statusColor = 'green';
    isSaving = false;

    private localFilters = {} as DefaultFilters;

    mapProviders = [
        { name: 'Google', value: 'Google' },
        { name: 'OpenStreetMap', value: 'OpenStreetMap' },
    ];

    get mapProvider() {
        return this.localFilters.mapProvider || this.userSettingsService.defaultFilters.mapProvider;
    }

    set mapProvider(v: string) {
        this.localFilters = {
            ...this.localFilters,
            mapProvider: v,
        };
    }

    get los() {
        return this.localFilters.los || this.userSettingsService.defaultFilters.los;
    }

    set los(v: number) {
        this.localFilters = {
            ...this.localFilters,
            los: v,
        };
    }

    get mealType() {
        return this.localFilters.mealType || this.userSettingsService.defaultFilters.mealType;
    }

    set mealType(v: string) {
        this.localFilters = {
            ...this.localFilters,
            mealType: v,
        };
    }

    get numberOfGuests() {
        return this.localFilters.numberOfGuests || this.userSettingsService.defaultFilters.numberOfGuests;
    }

    set numberOfGuests(v: number) {
        this.localFilters = {
            ...this.localFilters,
            numberOfGuests: v,
        };
    }

    get price() {
        return this.localFilters.price || this.userSettingsService.defaultFilters.price;
    }

    set price(v: string) {
        this.localFilters = {
            ...this.localFilters,
            price: v,
        };
    }

    get losItems() {
        return DEFAULT_LOS.map(v => ({
            name: String(v),
            value: v,
        } as Item));
    }

    get mealTypeItems() {
        return this.mealTypesService.mealTypeItems;
    }

    get priceItems() {
        return $enum(PRICE_TYPE).map((value): Item => ({
            value,
            name: this.$t(`filterSettings.price.${value}`) as string,
        }));
    }

    get nogItems() {
        return NUMBER_OF_GUESTS.map(v => ({
            name: String(v),
            value: v,
        } as Item));
    }

    get isChanged() {
        return !!Object.values(this.localFilters).find(v => v !== null);
    }

    async handleUpdate() {
        this.isSaving = true;
        try {
            await this.userSettingsService.updateDefaultFilters(this.localFilters);

            this.status = 'Saved!';
            this.statusColor = 'green';
            this.localFilters = {};
        } catch (e) {
            this.status = 'Error';
            this.statusColor = 'red';
            throw e;
        } finally {
            this.isSaving = false;
        }
    }
}
